<template>
  <div style="padding-bottom: 200px">
    <div style="background-color: #f2f2f2" class="justify-content-between flex">
      <div class="col-12 sm:col-6 text-center">
        <FilterSearchButton />
      </div>
      <div class="col-12 sm:col-6 text-center">
        <Button
          icon="pi pi-refresh"
          label="Refresh"
          class="p-button-text p-button-secondary button-"
        ></Button>
      </div>
    </div>
    <div v-for="u in notifications" :key="u.uid">
      <div
        :class="
          'highlight col-12 flex mt-2 mb-2 ml-1' +
          (u.active != 1 ? '' : 'opacity-50')
        "
      >
        <div class="flex align-items-center">
          <Skeleton
            v-if="isLoading"
            width="45px"
            height="45px"
            style="border-radius: 50%; margin-right: 12px"
          ></Skeleton>
          <ProgressAvatar
            v-else
            :image="u.user.profile_picture"
            :user="u"
            :progress="100"
            :strokeColor="'rgb(255, 171, 43)'"
            innerStrokeColor="#ffffff"
            :radius="25"
            class="mr-2"
          ></ProgressAvatar>
        </div>
        <div class="mt-2">
          <Skeleton
            v-if="isLoading"
            width="220px"
            style="margin-bottom: 5px"
          ></Skeleton>
          <template v-else>
            <span style="font-weight: bold" class="">
              {{ u.user.name }}
            </span>
          </template>
          <Skeleton
            v-if="isLoading"
            width="225px"
            style="margin-bottom: 5px"
          ></Skeleton>
          <span v-else> {{ addMessageType(u.message_type) }}</span>
          <!-- <span> {{ u.message_text }} </span> -->
          <Skeleton
            v-if="isLoading"
            width="125px"
            style="margin-bottom: 5px"
          ></Skeleton>
          <div @click="navigateToTask(u)" v-else class="text-link">
            {{ u.task.title }}
          </div>

          <div v-if="!isLoading" class="font-light text-sm mt-1">
            {{ formatDate(u.date) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import {
  fetchNotifications,
  clearNotifications,
} from "../../../services/data_service";
import { state } from "../../../services/data_service";

export default {
  name: "ContactSlider",
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await fetchNotifications();
    this.isLoading = false;
    clearNotifications();
  },
  methods: {
    addMessageType(type) {
      switch (type) {
        case "mention":
          return "mentioned you in";
        case "comment":
          return "commented on your message";
        case "review":
          return "requested a review on";
        case "note":
          return "left a note on";
        default:
          break;
      }
    },
    navigateToTask(notification) {
      this.clearSliderContent();
      this.$store.dispatch("setSelectedObject", {
        uid: notification.task.uid,
        mode: "notes",
      });

      // check job is already router
      if (
        this.$router.currentRoute.path !== `/job/${notification.job.uid}/tasks`
      ) {
        this.$router.push(`/job/${notification.job.uid}/tasks`);
        this.$bus.emit("refreshData");
      }

      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };
      return date.toLocaleString("en-GB", options);
    },
  },
  computed: {
    notifications() {
      return state.notifications;
    },
  },
};
</script>
<style>
.highlight {
  transition: background-color 0.5s;
}

.highlight:hover {
  background-color: #f2f4f6;
  cursor: pointer;
}
</style>